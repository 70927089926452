<template>
    <v-form ref="form">
        <v-card style="padding: 20px;">
            <v-card-title>
                <label class="display-1 font-weight-bold">Данные продавца</label>
                <v-spacer/>
                <v-btn icon large @click="$emit('close')">
                    <v-icon large>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-layout style="margin-top: 20px;">
                    <label class="subtitle-1 label">Номер телефона</label>
                    <v-fade-transition mode="out-in">
                        <template v-if="isEditPhone">
                            <v-layout column key="edit">
                                <v-layout>
                                    <v-select :items="countryItems"
                                              color="secondary"
                                              item-value="idCountry"
                                              :loading="countriesLoading"
                                              hide-details
                                              item-color="secondary"
                                              :disabled="phoneLoading"
                                              v-model="selectedCountry"
                                              style="max-width: 100px; margin-top: -15px;">
                                        <template v-slot:item=" { item } ">
                                            <label class="subtitle-1">{{item.countryFlag}}</label>
                                            <label class="subtitle-1" style="margin-left: 15px;">{{item.title}}</label>
                                            <label class="subtitle-2 grey--text"
                                                   style="margin-left: 5px; margin-top: 4px;">{{item.phonePrefix}}</label>
                                        </template>

                                        <template v-slot:selection=" { item } ">
                                            <label class="subtitle-1">{{item.countryFlag}}</label>
                                            <label class="subtitle-1"
                                                   style="margin-left: 10px;">{{item.phonePrefix}}</label>
                                        </template>
                                    </v-select>

                                    <v-layout column>
                                        <the-mask v-model="curItem.phone"
                                                  class="subtitle-1 font-weight-regular black--text phone-input"
                                                  @input="phoneTaken = false"
                                                  :disabled="phoneLoading"
                                                  :mask="phoneMask"/>
                                        <v-slide-y-transition>
                                            <label class="caption error--text" v-if="phoneTaken">
                                                Пользователь с указанным номером уже есть в базе</label>
                                        </v-slide-y-transition>
                                    </v-layout>

                                </v-layout>
                                <v-btn color="secondary"
                                       @loading="phoneLoading"
                                       @click="savePhone"
                                       :disabled="isSaveButtonDisabled"
                                       style="width: 160px; height: 50px; border-radius: 8px; margin-top: 5px;">
                                    Сохранить
                                </v-btn>
                            </v-layout>
                        </template>

                        <template v-else>
                            <v-layout key="view">
                                <label class="subtitle-1">{{user.phone}}</label>
                                <label class="subtitle-1 font-weight-bold pointer"
                                       @click="openEditPhone"
                                       style="margin-left: 15px;">Изменить</label>
                            </v-layout>
                        </template>
                    </v-fade-transition>
                </v-layout>

                <v-layout style="margin-top: 25px;">
                    <label class="subtitle-1 label">Имя</label>
                    <label class="subtitle-1">{{user.firstName}}</label>
                </v-layout>

                <v-layout style="margin-top: 15px;" align-center>
                    <label class="subtitle-1 label">Верифицирован</label>
                    <v-select :items="identifyRequestStatus.selectItems"
                              item-text="title"
                              item-color="secondary"
                              v-model="curItem.idRequestStatus"
                              class="sub"
                              style="margin-top: -5px; max-width: 300px;"
                              :disabled="controlLoading"
                              @change="controlRequest"
                              item-value="idRequestStatus"/>
                    <v-progress-circular indeterminate
                                         style="margin: -15px 0 0 -50px; max-width: 20px;"
                                         color="secondary"
                                         v-if="controlLoading"/>
                </v-layout>

                <v-layout style="margin-top: 15px;">
                    <label class="subtitle-1 label">Почта</label>
                    <label class="subtitle-1">{{user.email}}</label>
                </v-layout>

                <v-layout style="margin-top: 25px;">
                    <label class="subtitle-1 label">Страна</label>
                    <label class="subtitle-1">{{user.countryTitle}}</label>
                </v-layout>

                <v-layout style="margin-top: 25px;">
                    <label class="subtitle-1 label">Доп. номер 1</label>
                    <label class="subtitle-1">{{user.phoneInfo1 || '-'}}</label>
                </v-layout>

                <v-layout style="margin-top: 25px;">
                    <label class="subtitle-1 label">Доп. номер 2</label>
                    <label class="subtitle-1">{{user.phoneInfo2 || '-'}}</label>
                </v-layout>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script>
    import {TheMask} from 'vue-the-mask'

    import ValidationMixin from "../../mixins/ValidationMixin";

    export default {
        name: "seller-form",
        components: {
            TheMask
        },
        mixins: [ValidationMixin],
        props: {
            user: {
                type: Object,
                required: true
            },
            isVisibleEditModal: {
                type: Boolean,
                required: true
            },
            editLoading: {
                type: Boolean,
                required: true
            }
        },
        data: () => ({
            curItem: {},
            controlLoading: false,
            isEditPhone: false,
            phoneMask: "",
            selectedCountry: null,
            countriesLoading: false,
            phoneLoading: false,
            phoneTaken: false
        }),
        computed: {
            countTitles() {
                return ['запрос', 'запросос', 'запроса']
            },
            identifyRequestStatus() {
                return this.$store.state.identifyRequestStatus
            },
            countryItems() {
                return this.$store.state.sellerCountryItems
            },
            isSaveButtonDisabled() {
                console.log(this.countryItems, this.selectedCountry);
                let country = this.countryItems.filter(v => v.idCountry === this.selectedCountry)[0];
                console.log(this.unmaskValue(this.curItem.phone).length !== this.unmaskValue(country.phoneMask).length );
                return this.unmaskValue(this.curItem.phone).length !== this.unmaskValue(country.phoneMask).length
            }
        },
        watch: {
            isVisibleEditModal(to) {
                if (this.$refs.form)
                    this.$refs.form.resetValidation();
                if (to) {
                    this.getFullInfo();
                    this.curItem = {...this.user, requests: []};
                }
                this.isEditPhone = false
            },
            selectedCountry(to) {
                this.phoneTaken = false;
                if (to) this.updatePhoneByCountry(to)
            },
            user(to) {
                this.curItem.isBlocked = to.isBlocked;
                this.curItem.phone = to.phone
            },
            isEditPhone(to) {
                if (!to) this.selectedCountry = null
            }
        },
        methods: {
            saveItem() {
                if (this.$refs.form.validate()) {
                    this.$emit('saveItem', this.curItem)
                }
            },
            getFullInfo() {
                this.requestsLoading = true;
                setTimeout(() => {
                    this.requestsLoading = false
                }, 500)
            },
            controlRequest(event) {
                this.controlLoading = true;
                const params = {
                    headers: {token: localStorage.getItem('token')}
                };
                const postData = {
                    idSeller: this.curItem.idSeller,
                    idRequestStatus: event
                };
                this.$http.post("/sellers/control", postData, params)
                    .then(response => {
                        console.log(response.data.data)
                        this.$emit("updateSeller", {
                            idSeller: response.data.data.idSeller,
                            key: "idRequestStatus",
                            value: response.data.data.idRequestStatus
                        })
                    }).catch(error => {
                    console.log(error)
                    switch (error.response.status) {
                        case 406:
                            // this.$router.history.push({name: 'login'})
                            break;
                        case 403:
                            alert("Ошибка аргументов")
                            break;
                        case 401:
                            this.$router.history.push({name: 'login'});
                            break;
                        default:
                            console.log('error');
                            break;
                    }
                }).finally(() => {
                    setTimeout(() => {
                        this.controlLoading = false
                    }, 500)
                })
            },
            unmaskValue(text) {
                const defaultDelimiters = /[-!$%^&*()_+|~=`{}[\]:";'<>?,./\\ ]/
                return text ? String(text).replace(new RegExp(defaultDelimiters, 'g'), '') : text
            },
            getCountries() {
                this.countriesLoading = true;
                const params = {
                    headers: {token: localStorage.getItem('token')},
                    params: {
                        role: 'seller'
                    }
                };
                this.$http.get("/countries", params)
                    .then(response => {
                        this.$store.dispatch('setSellerCountryItems', response.data.data)
                    })
                    .catch(error => {
                        switch (error.response.status) {
                            case 406:
                                // this.$router.history.push({name: 'login'})
                                break;
                            case 401:
                                this.$router.history.push({name: 'login'});
                                break;
                            default:
                                console.log('error');
                                break;
                        }
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.countriesLoading = false
                        }, 500)
                    })
            },
            openEditPhone() {
                this.selectedCountry = this.curItem.idCountry;
                this.isEditPhone = true;
            },
            updatePhoneByCountry(idCountry) {
                let country = this.countryItems.filter(v => v.idCountry === idCountry)[0];
                this.phoneMask = country.phoneMask.replace(/x/g, '#');
                this.$nextTick(() => {
                    this.curItem.phone = this.user.phone.substring(country.phonePrefix.length);
                    console.log(this.phoneMask, this.curItem.phone, this.user.phone, country.phonePrefix.length);
                })
            },
            savePhone() {
                this.phoneLoading = true;
                const params = {
                    headers: {token: localStorage.getItem('token')}
                };

                const postData = {
                    idSeller: this.user.idSeller,
                    idCountry: this.selectedCountry,
                    phone: this.unmaskValue(this.curItem.phone)
                };
                console.log(postData)
                this.$http.post("/sellers/update", postData, params)
                    .then(response => {
                        this.curItem.phone = response.data.data.phone;
                        this.saveItem();
                        this.isEditPhone = false
                    })
                    .catch(error => {
                        console.log(error)
                        switch (error.response.status) {
                            case 406:
                                // this.$router.history.push({name: 'login'})
                                break;
                            case 401:
                                this.$router.history.push({name: 'login'});
                                break;
                            case 409:
                                this.phoneTaken = true;
                                break;
                            default:
                                console.log('error');
                                break;
                        }
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.phoneLoading = false
                        }, 500)
                    })
            }
        },
        beforeMount() {
            this.curItem = {...this.user, requests: []};
            this.getFullInfo();

            if (!this.countryItems.length) this.getCountries()
        }
    }
</script>

<style scoped>
    .label {
        width: 180px;
    }

    .pointer {
        color: black;
    }

    .pointer:hover {
        color: grey;
        cursor: pointer;
    }

    .phone-input {
        max-width: 150px;
        padding: 3px;
        margin-left: 10px;
        margin-top: -5px;
        border-bottom: 1px solid grey;
    }
</style>